<template>
  <div>
    <p class="mb-6">Valorizado de inventario</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <select-puesto
              v-model="filtros.id_puesto"
              :add="false"
              :dense="true"
              :outlined="true"
              :clearable="true"
              :ind_principal="true"
              :storePuesto="true"
              :todos="true"
              :filtros="{id_reporte:48}"
            ></select-puesto>
          </v-col>
          <v-col cols="12" sm="6">
            <DatePicher :dense="true" :outlined="true" v-model="filtros.fecha"></DatePicher>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-btn small :loading="cargando" :disabled="cargando" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <TablaSimpleReporte
          @GetVer="GetVerComanda"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL']"
          :columnas="false"
          :orientacionReporte="'l'"
        ></TablaSimpleReporte>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <h4>Total Valorizado</h4>
          </v-col>
          <v-col>
            <h4 class="text-right">$ {{ funciones.formatoNumeric(total, 6) }}</h4>
            <h4 class="text-right">Bs {{ funciones.formatoNumeric(total_bs, 6) }}</h4>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="text-center" v-if="datos.length > 0">
        <v-btn class="error mr-2" @click="GetPdf(0)"
          >PDF $<v-icon small>{{ icons.mdiFilePdf }}</v-icon>
        </v-btn>
        <v-btn class="error mr-2" @click="GetPdf(1)"
          >PDF Bs<v-icon small>{{ icons.mdiFilePdf }}</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
    <Pdf ref="pdfRef"></Pdf>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline, mdiFilePdf } from '@mdi/js'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import { onBeforeMount, ref } from '@vue/composition-api'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuesto2.vue' //'../../../archivo/puesto/components/SelectPuesto.vue';
import ProductoService from '@/api/servicios/ProductosService'
import MaestroService from '@/api/servicios/MaestroService'
import moment from 'moment'
import store from '@/store'
import SelectCategoria from '../../maestro/categoria/componentes/Select.vue'
import SelectSubCatoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectProductoTipo from '@/views/sistema/inventario/maestro/producto-tipo/componentes/Select.vue'
import DatePicher from '@/components/DatePicker.vue'
import funciones from '@/funciones/funciones'
import Pdf from '@/components/PdfFormatoLibreDocumentos.vue'
// demos

export default {
  props: {
    id_puesto: {
      type: String,
      default: -1,
    },
  },
  components: {
    TablaSimpleReporte,
    SelectPuesto,
    DatePicher,
    Pdf,
  },
  watch: {
    userCliente: function (old, news) {
      if (old != news) {
        this.filtros.id_sub_categoria = null
      }
    },
    'filtros.id_puesto': function (old, news) {
      if (old != news) {
        this.filtros.id_almacen = null
      }
    },
    id_puesto: function (old, news) {
      if (old != news) {
        this.filtros.id_puesto = old
      }
    },
  },
  setup(props) {
    onBeforeMount(() => {
      if (props.id_puesto != -1) {
        filtros.value.id_puesto = props.id_puesto
      }
    })
    const pdfRef = ref()
    const nombreReporte = ref(`Valorizado de inventario`)
    const itemsExistencia = [
      {
        id: 0,
        text: 'TODOS',
      },
      {
        id: 1,
        text: 'SOLO POSITIVOS',
      },
      {
        id: 2,
        text: 'SOLO NEGATIVOS',
      },
      {
        id: 3,
        text: 'SOLO NEGATIVOS Y POSITIVOS',
      },
      {
        id: 4,
        text: 'SOLO EN CERO',
      },
    ]

    const cabecera = ref([])

    const datos = ref([])
    const filtros = ref({
      fecha: null,
      id_puesto: null,
    })

    const cargando = ref(false)
    const total = ref(0)
    const total_bs = ref(0)
    const otros_datos = ref({
      filtros: {},
      area: {},
    })
    const buscarDatos = () => {
      cargando.value = true
      cabecera.value = [
        { text: 'Id', value: 'id_producto', format: { small: true } },
        { text: 'Producto', value: 'nombre', format: { small: true } },
        { text: 'Categoria', value: 'categoria', format: { small: true } },
        { text: 'Sub Categoria', value: 'sub_categoria', format: { small: true } },
        { text: 'Unidad', value: 'unidad_medida', format: { small: true } },
      ]
      MaestroService.reporteValorizadoGeneral({ ...filtros.value })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos

            nombreReporte.value = `Valorizado de inventario al ${filtros.value.fecha}`
            total.value = response.data.totalizado.total 
            total_bs.value = response.data.totalizado.total_bs

            otros_datos.value.area = response.data.area
            otros_datos.value.filtros = filtros.value

            if (datos.value.length > 0) {
              let valores = Object.keys(datos.value[0]) // valores = ["Scott", "Negro", true, 5];
              const arrayVal = [
                'id_producto',
                'max_id',
                'existencia',
                'nombre',
                'tasa',
                'costo',
                'tota_costo',
                'costo_pais',
                'tota_costo_pais',
                'unidad_medida',
                'unidad_medida_unidad',
                'categoria',
                'sub_categoria',
              ]

              for (let i = 0; i < valores.length; i++) {
                if (!arrayVal.includes(valores[i])) {
                  console.log(valores[i])
                  cabecera.value.push({ text: valores[i], value: valores[i], format: { small: true } })
                }
              }

              cabecera.value = cabecera.value.concat([
                { text: 'Cantidad', value: 'existencia', format: { small: true } },
              ])

              cabecera.value = cabecera.value.concat([
                { text: 'Cantidad', value: 'existencia', format: { small: true } },
                { text: 'Costo $', value: 'costo', format: { small: true } },
                { text: 'Total Costo $', value: 'tota_costo', format: { small: true } },
                { text: 'Costo Bs', value: 'costo_pais', format: { small: true } },
                { text: 'Total Costo Bs', value: 'tota_costo_pais', format: { small: true } },
              ])
            }

            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'waring',
            })
          }
        })
        .catch(err => {})
        .finally(() => {
          cargando.value = false
        })
    }

    const GetPdf = (tipo) => {
      const cuerpoTablaDetalles = []

      datos.value.forEach(item => {
        cuerpoTablaDetalles.push([
          item.id_producto.toString(),
          item.nombre,
          item.unidad_medida,
          funciones.formatoNumeric(item.existencia),
          (tipo == 0 ? '$':'BS') + funciones.formatoNumeric((tipo == 0 ? item.costo : item.costo_pais)),
          (tipo == 0 ? '$':'BS') + funciones.formatoNumeric((tipo == 0 ?  item.tota_costo : item.tota_costo_pais)),
        ])
      })

      const datosInit = {
        cabecera1: { visible: true },
        nombreDocumento: `Valorizado invetario al ${otros_datos.value.filtros.fecha}`,

        numeroDocumento: {
          texto: 'Valorizado de inventario',
          valor: '',
        },
        numeroControl: {
          texto: ' ',
          valor: otros_datos.value.area.nombre + '-' + otros_datos.value.filtros.fecha,
        },
        numeroFecha: {
          texto: 'Fecha',
          valor: otros_datos.value.filtros.fecha,
        },
        sub1: {
          linea1: [{ texto: 'Area:', valor: otros_datos.value.area.nombre }],
          linea2: [
            { texto: 'Fecha Creacion:', valor: funciones.formatoFecha(moment(), 3) },
            { texto: 'Creado por:', valor: store.state.user.nombre + ' ' + store.state.user.apellido },
          ],
        },
        sub2: {
          linea1: [],
          linea2: [],
        },

        pie: {
          nota: {
            texto: ' ',
            valor: ' ',
          },
          totales: [
            {
              texto: 'Total Valorizado:',
              valor: (tipo == 0 ? '$':'BS') + funciones.formatoNumeric((tipo == 0 ? total.value :  total_bs.value), 2),
            },
          ],
        },

        detalles: {
          cabecera: [
            { texto: 'Id', posicion: '45', alineacion: 'left' },
            {
              texto: 'Producto',
              posicion: '80',
              alineacion: 'left',
              justificar: { maxWidth: 200, align: 'justify', maximoItems: 33 },
            },
            { texto: 'UndMed', posicion: '320', alineacion: 'left' },
            { texto: 'Cant', posicion: '400', alineacion: 'right' },
            { texto: 'Costo ', posicion: '480', alineacion: 'right' },
            { texto: 'Total Costo', posicion: '555', alineacion: 'right' },
          ],
          cuerpo: cuerpoTablaDetalles,
        },
      }

      if (tipo == 1) {
        datosInit.sub1.linea1.push({ texto: 'Tasa:', valor: funciones.formatoNumeric(datos.value[0].tasa,2)})
      } 
      

      console.log(datosInit)
      pdfRef.value.generarPDF(datosInit)
    }



    return {
      icons: {
        mdiFilePdf,
      },
      cabecera,
      datos,
      buscarDatos,
      cargando,
      nombreReporte,
      filtros,
      itemsExistencia,
      total,
      funciones,
      pdfRef,
      GetPdf,
      total_bs
    }

  },
}
</script>
